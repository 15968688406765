import React, { useEffect, useState } from "react"
import { message, Tooltip, Button, Modal, Input } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { subHours, eachHourOfInterval, format as dateFormat } from "date-fns"
import ChartLine from "@/components/ChartLine"
import { truncate, numberInput, format } from '@/utils'
import tokens from '@/tokens'
import Empty from '@/components/Empty'
import * as style from "./style.module.scss"

const xrayFingerptint = 'asset14y0dxsz9s9nd2lefkqvuu7edqlsg5p70r3wyxa'

const adaSlot = {
  fingerprint: 'ada',
  img: 'ada.png',
  price: 1,
  balance: 156365.121993,
  ticker: 'ADA',
  data: [],
}

const popularTokens = [
  adaSlot,
  tokens[0],
  tokens[2],
  tokens[1],
]

const Swap = () => {
  const dispatch = useDispatch()
  const slippage = useSelector((state) => state.settings.slippage)
  const customSlippage = useSelector((state) => state.settings.customSlippage)
  const [selectTokenModal, setSelectTokenModal] = useState(false)
  const [selectTokenModalOption, setSelectTokenModalOption] = useState('tokenA')
  const [tokenFilter, setTokenFilter] = useState('')
  const [tokenAValue, setTokenAValue] = useState('')
  const [tokenBValue, setTokenBValue] = useState('')
  const [tokenA, setTokenA] = useState('ada')
  const [tokenB, setTokenB] = useState('asset14y0dxsz9s9nd2lefkqvuu7edqlsg5p70r3wyxa')

  const tokenASlot = tokenA === 'ada' ? adaSlot : tokens.filter((token) => token.fingerprint === tokenA)[0]
  const tokenBSlot = tokenB === 'ada' ? adaSlot : tokens.filter((token) => token.fingerprint === tokenB)[0]

  const switchAB = () => {
    setTokenA(tokenB)
    setTokenB(tokenA)
    setTokenAValue('')
    setTokenBValue('')
  }

  const setPredefinedSlippage = (value) => {
    dispatch({
      type: "settings/CHANGE_SETTING",
      payload: {
        setting: "slippage",
        value: value,
      },
    })
    dispatch({
      type: "settings/CHANGE_SETTING",
      payload: {
        setting: "customSlippage",
        value: false,
      },
    })
  }

  const setValueSlippage = (value) => {
    if (value) {
      if (numberInput(value)) {
        dispatch({
          type: "settings/CHANGE_SETTING",
          payload: {
            setting: "slippage",
            value: value > 95 ? 95 : value < 1 ? 1 : value,
          },
        })
        dispatch({
          type: "settings/CHANGE_SETTING",
          payload: {
            setting: "customSlippage",
            value: true,
          },
        })
      }
    } else {
      setPredefinedSlippage(1)
    }
  }

  const labels = [...eachHourOfInterval({ start: subHours(new Date(), 5), end: new Date() }).map((d) => dateFormat(d, 'hh:mm:ss')), 'Current']

  const openModal = (option) => {
    setSelectTokenModal(true)
    setSelectTokenModalOption(option)
  }

  const onCopy = () => {
    message.success('Fingerprint copied')
  }

  const selectFilteredTokens = [adaSlot, ...tokens]
    .filter((token) => {
      return token.fingerprint.toLowerCase().includes(tokenFilter) || token.ticker.toLowerCase().includes(tokenFilter)
    })

  const selectToken = (fingerprint) => {
    if (selectTokenModalOption === 'tokenA') {
      setSelectTokenModal(false)
      setTokenA(fingerprint)
      setTokenB(fingerprint === 'ada' ? xrayFingerptint : 'ada')
      setTokenFilter('')
    } else {
      setSelectTokenModal(false)
      setTokenB(fingerprint)
      setTokenA(fingerprint === 'ada' ? xrayFingerptint : 'ada')
      setTokenFilter('')
    }
  }

  const finalDataset = tokenASlot.fingerprint === 'ada'
    ? tokenBSlot.data.map((price) => 1 / price)
    : tokenASlot.data
  const finalPrice = finalDataset[6]
  const finalExpectedOutput = finalPrice * tokenAValue || 0
  const finalMinimum = finalExpectedOutput - finalExpectedOutput / 100 * slippage
  const finalPriceImpact = 1.2
  const finalDeposit = 2
  const finalNetworkFee = 0.176835
  const finalServiceFee = 1

  const valueAChange = (value) => {
    if (numberInput(value)) {
      setTokenAValue(value)
    }
  }

  const valueBChange = (value) => {
    if (numberInput(value)) {
      setTokenAValue(parseFloat((value * tokenBSlot.price).toFixed(6)) || '')
    }
  }

  return (
    <div className="ray__block">
      <Modal
        title={null}
        footer={null}
        visible={selectTokenModal}
        onCancel={() => {
          setSelectTokenModal(false)
          setTokenFilter('')
        }}
        width={540}
        closeIcon={<span className="ri ri-close" />}
        zIndex={1001}
      >
        <div className="ray__heading mb-3">Select a token</div>
        <Input
          size="large"
          placeholder="Enter ticker name or fingerprint"
          allowClear
          value={tokenFilter}
          onChange={(e) => setTokenFilter(e.target.value.toLowerCase())}
        />
        <div className={style.selectPopular}>
          {popularTokens.map((token) => {
            return (
              <div
                key={token.fingerprint}
                onClick={() => selectToken(token.fingerprint)}
                onKeyPress={() => selectToken(token.fingerprint)}
              >
                <img src={`/resources/tokens/${token.img}`} alr={token.title} />
                {token.ticker}
              </div>
            )
          })}
        </div>
        <div className={style.selectList}>
          {!!selectFilteredTokens.length && selectFilteredTokens.map((token) => {
            return (
              <div
                className={style.selectListItem}
                key={token.fingerprint}
                onClick={() => selectToken(token.fingerprint)}
                onKeyPress={() => selectToken(token.fingerprint)}
              >
                <div className={style.selectListItemImg}>
                  <img src={`/resources/tokens/${token.img}`} alr={token.title} />
                </div>
                <div className={style.selectListItemTitle}>
                  <div><strong>{token.ticker}</strong></div>
                  <div className={style.selectListItemTitleDescr}>
                    {truncate(token.fingerprint)}
                  </div>
                </div>
                <div className={style.selectListItemAmount}>
                  {token.balance > 0 && `${token.balance} ${token.ticker}`} 
                </div>
              </div>
            )
          })}
          {!selectFilteredTokens.length && <Empty title="No Tokens Found" message="Try another ticker name or fingerprint" />}
        </div>
      </Modal>
      <div className={style.container}>
        <div className={style.token}>
          <div className={style.tokenInput}>
            <div className={style.tokenInputValue}>
              <input
                placeholder="0"
                value={tokenAValue}
                onChange={(e) => valueAChange(e.target.value)}
              />
            </div>
            <CopyToClipboard text={tokenASlot.fingerprint} onCopy={onCopy}>
              <Tooltip title="Copy Fingerprint">
                <div className={style.tokenInputInfo}>
                  {truncate(tokenASlot.fingerprint)}
                </div>
              </Tooltip>
            </CopyToClipboard>
          </div>
          <div
            className={style.tokenButton}
            onClick={() => { openModal('tokenA') }}
            onKeyPress={() => { openModal('tokenA') }}
          >
            <div className={style.tokenButtonImage}><img src={`/resources/tokens/${tokenASlot.img}`} alt={tokenASlot.ticker} /></div>
            <div className={style.tokenButtonTitle}>{tokenASlot.ticker}</div>
            <div className={style.tokenButtonArrow}><i className="ri ri-chevron_down" /></div>
          </div>
        </div>
        <div
          className={style.switcher}
          onClick={switchAB}
          onKeyPress={switchAB}
        >
          <i className="ri ri-arrow_down" />
        </div>
        <div className={style.token}>
          <div className={style.tokenInput}>
            <div className={style.tokenInputValue}>
              <input
                placeholder="0"
                value={parseFloat((finalExpectedOutput).toFixed(6)) || ''}
                onChange={(e) => valueBChange(e.target.value)}
              />
            </div>
            <CopyToClipboard text={tokenBSlot.fingerprint} onCopy={onCopy}>
              <Tooltip title="Copy Fingerprint">
                <div className={style.tokenInputInfo}>
                  {truncate(tokenBSlot.fingerprint)}
                </div>
              </Tooltip>
            </CopyToClipboard>
          </div>
          <div
            className={style.tokenButton}
            onClick={() => { openModal('tokenB') }}
            onKeyPress={() => { openModal('tokenB') }}
          >
            <div className={style.tokenButtonImage}><img src={`/resources/tokens/${tokenBSlot.img}`} alt={tokenBSlot.ticker} /></div>
            <div className={style.tokenButtonTitle}>{tokenBSlot.ticker}</div>
            <div className={style.tokenButtonArrow}><i className="ri ri-chevron_down" /></div>
          </div>
        </div>
        <div className={style.params}>
          <div>
            <div className="ray__heading mb-0">1 {tokenASlot.ticker} = {format(finalPrice, 6)} {tokenBSlot.ticker}</div>
            <div style={{ maxWidth: '9rem', height: 30 }}>
              <ChartLine dataset={finalDataset} labels={labels} height={30} postfix={tokenBSlot.ticker} />
            </div>
          </div>
          <div className={style.paramsSlippage}>
            <div className="ray__heading">
              Slippage{" "}
              <Tooltip title="The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert">
                <i className="ri ri-info font-size-12" />
              </Tooltip>
            </div>
            <div className={style.paramsSlippageControls}>
              <div
                className={`${style.paramsSlippageBtn} ${(slippage === 1 && !customSlippage) ? style.paramsSlippageSuccess : ''}`}
                onClick={() => setPredefinedSlippage(1)}
                onKeyPress={() => setPredefinedSlippage(1)}
              >
                1%
              </div>
              <div
                className={`${style.paramsSlippageBtn} ${(slippage === 3 && !customSlippage) ? style.paramsSlippageSuccess : ''}`}
                onClick={() => setPredefinedSlippage(3)}
                onKeyPress={() => setPredefinedSlippage(3)}
              >
                3%
              </div>
              <label
                className={`${style.paramsSlippageInput} ${(customSlippage) ? style.paramsSlippageSuccess : ''}`}
                htmlFor="slippage"
              >
                <input
                  id="slippage"
                  autoComplete="off"
                  onChange={(e) => setValueSlippage(e.target.value)}
                  value={customSlippage ? slippage : ''}
                />%
              </label>
            </div>
          </div>
        </div>
        <div className={style.tx}>
          <div className={style.txTitle}>Expected Output</div>
          <div className={style.txValue}>{format(finalExpectedOutput, 6)} {tokenBSlot.ticker}</div>
        </div>
        <div className={style.tx}>
          <div className={style.txTitle}>
            Minimum after slippage ({slippage}%){" "}
            <Tooltip title="The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert">
              <i className="ri ri-info font-size-12" />
            </Tooltip>
          </div>
          <div className={style.txValue}>{format(finalMinimum, 6)} {tokenBSlot.ticker}</div>
        </div>
        <div className={style.tx}>
          <div className={style.txTitle}>
            Price Impact{" "}
            <Tooltip title="The impact your trade has on the market price of this pool">
              <i className="ri ri-info font-size-12" />
            </Tooltip>
          </div>
          <div className={style.txValue}>+{finalPriceImpact}%</div>
        </div>
        <div className={style.tx}>
          <div className={style.txTitle}>
            Deposit{" "}
            <Tooltip title="The deposit will be returned with the swapped tokens">
              <i className="ri ri-info font-size-12" />
            </Tooltip>
          </div>
          <div className={style.txValue}>{finalDeposit} ADA</div>
        </div>
        <div className={style.tx}>
          <div className={style.txTitle}>Network + Service Fee</div>
          <div className={style.txValue}>{format(finalNetworkFee + finalServiceFee, 6)} ADA</div>
        </div>
        <div className="mt-4">
          <span className="ant-btn ray__btn ray__btn--large ray__btn--primary w-100">
            <i className="ri ri-repeat me-2" />
            Swap
          </span>
        </div>
      </div>
    </div>
  )
}

export default Swap
