// extracted by mini-css-extract-plugin
export var container = "style-module--container--LwUuF";
export var params = "style-module--params--jGtMt";
export var paramsSlippage = "style-module--paramsSlippage--w6Ltr";
export var paramsSlippageBtn = "style-module--paramsSlippageBtn--j17uf";
export var paramsSlippageControls = "style-module--paramsSlippageControls--tYc6Q";
export var paramsSlippageInput = "style-module--paramsSlippageInput--XrfmT";
export var paramsSlippageSuccess = "style-module--paramsSlippageSuccess--Fc9Qe";
export var selectList = "style-module--selectList--x91rs";
export var selectListItem = "style-module--selectListItem--yMfjX";
export var selectListItemAmount = "style-module--selectListItemAmount--t5prm";
export var selectListItemImg = "style-module--selectListItemImg--TRjxu";
export var selectListItemTitle = "style-module--selectListItemTitle--EURLk";
export var selectListItemTitleDescr = "style-module--selectListItemTitleDescr--OIct1";
export var selectPopular = "style-module--selectPopular--RJy-e";
export var selectPopularActive = "style-module--selectPopularActive--Ps3LP";
export var selectTitle = "style-module--selectTitle--rwIQi";
export var switcher = "style-module--switcher--SB5jm";
export var token = "style-module--token--NByvx";
export var tokenButton = "style-module--tokenButton--RMqgO";
export var tokenButtonArrow = "style-module--tokenButtonArrow--tk1qd";
export var tokenButtonImage = "style-module--tokenButtonImage--M4fWD";
export var tokenButtonTitle = "style-module--tokenButtonTitle--1C-9l";
export var tokenInput = "style-module--tokenInput--7eKOI";
export var tokenInputInfo = "style-module--tokenInputInfo--JIlL8";
export var tokenInputValue = "style-module--tokenInputValue--1K0am";
export var tx = "style-module--tx--3-WY5";
export var txTitle = "style-module--txTitle--GcT0G";
export var txValue = "style-module--txValue--xCmHy";